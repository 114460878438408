import React from 'react'

import Navbar from '../components/Navbar'
import Footer from "../components/Footer";
import style from '../styles/Developers'

// Import Material UIs
import {
    Box,
    Typography,
    Grid,
} from "@mui/material";

// Import Image
import norman from '../image/pic/1.jpg'
import earl from '../image/pic/2.jpg'
import dahrell from '../image/pic/3.jpg'
import eugene from '../image/pic/4.jpg'
import johnlloyd from '../image/pic/5.jpg'

function Developers({ isAuth }) {
    return (
        <div>
            {/* Main Container */}
            <Box>
                {/* Navbar */}
                <Navbar isAuth={isAuth} />

                {/* Middle Container */}
                <Box sx={style.mainContainer}>

                    <Box sx={style.promotionalSpaceContainer}>
                        {/* title */}
                        <Box sx={style.titleContainer}>
                            <Typography sx={style.promotionalTitle}>All You Need To Know About Us</Typography>
                            <Typography sx={style.promotionalTitleSub}></Typography>
                        </Box>

                        <Box sx={style.contentContainer}>
                            <Typography sx={style.contentText}>
                                We are the students of Bulacan State University(Bustos Campus) taking Bachelor of Science in Information and Technology.
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={style.promotionalSpaceContainer}>
                        {/* title */}
                        <Box sx={style.titleContainer}>
                            <Typography sx={style.promotionalTitle}>We The Developers </Typography>
                            <Typography sx={style.promotionalTitleSub}></Typography>
                        </Box>

                        {/* Developers */}
                        <Grid container sx={style.mainProfileDev} spacing={2}>

                            {/* Per Item */}
                            <Grid item sx={style.profileDev}>
                                <Box sx={style.imageContainer}>
                                    <img
                                        src={norman}
                                        alt="Harold"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                                <Typography sx={style.profilePosition}>Leader - Programmer</Typography>
                                <Typography sx={style.profileName}>Norman Nicolas</Typography>
                            </Grid>

                            {/* Per Item */}
                            <Grid item sx={style.profileDev}>
                                <Box sx={style.imageContainer}>
                                    <img
                                        src={earl}
                                        alt="Harold"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                                <Typography sx={style.profilePosition}>Programmer</Typography>
                                <Typography sx={style.profileName}>Earl Gerald Menoza</Typography>
                            </Grid>

                            {/* Per Item */}
                            <Grid item sx={style.profileDev}>
                                <Box sx={style.imageContainer}>
                                    <img
                                        src={dahrell}
                                        alt="Harold"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                                <Typography sx={style.profilePosition}>Documentations</Typography>
                                <Typography sx={style.profileName}>Dahrell Maquimot</Typography>
                            </Grid>

                            {/* Per Item */}
                            <Grid item sx={style.profileDev}>
                                <Box sx={style.imageContainer}>
                                    <img
                                        src={eugene}
                                        alt="Harold"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                                <Typography sx={style.profilePosition}>Documentations</Typography>
                                <Typography sx={style.profileName}>Eugene San Juan</Typography>
                            </Grid>

                            {/* Per Item */}
                            <Grid item sx={style.profileDev}>
                                <Box sx={style.imageContainer}>
                                    <img
                                        src={johnlloyd}
                                        alt="Harold"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                                <Typography sx={style.profilePosition}>Documentations</Typography>
                                <Typography sx={style.profileName}>Johnlloyd Cao</Typography>
                            </Grid>

                        </Grid>
                    </Box>


                </Box>

                {/* Footer */}
                <Footer />
            </Box>
        </div>
    )
}

export default Developers